<template>
  <v-container fluid>
    <v-layout
      row
      wrap
      v-if="
        getStudentFormFillUpSubject.hasOwnProperty('form_fill_up') &&
        ffEligibleStatusCheck
      "
    >
      <v-flex xs12>
        <v-card>
          <v-container fluid grid-list-md>
            <v-toolbar flat dark>
              <v-toolbar-title>
                {{
                  `Form Fill Up for
                ${getStudentFormFillUpSubject.form_fill_up.exam_master.exam_name}
                (${getStudentFormFillUpSubject.form_fill_up.exam_master.exam_year})`
                }}
              </v-toolbar-title>
            </v-toolbar>
          </v-container>
        </v-card>
      </v-flex>
      <v-flex xs4 class="pr-1">
        <v-card>
          <v-container fluid grid-list-md>
            <StudentInfo />
            <FormFillUpFee />
            <formNotification />
          </v-container>
        </v-card>
      </v-flex>
      <v-flex xs8>
        <v-card>
          <v-container
            fluid
            grid-list-md
            v-if="getStudentFormFillUpSubject.total_subject"
          >
            <compulsorySubject
              v-if="getStudentFormFillUpSubject.compulsory_subject.length > 0"
            />
            <electiveSubject
              v-if="
                Object.keys(getStudentFormFillUpSubject.elective_subject).length
              "
            />

            <optionalSubject
              v-if="getStudentFormFillUpSubject.optional_subject.length > 0"
            />
            <improvementSubject
              v-if="getStudentFormFillUpSubject.improvement_subject.length"
            />
            <v-toolbar flat dark>
              <v-toolbar-title>Update Mobile No.</v-toolbar-title>
            </v-toolbar>
            <v-card>
              <v-card-text>
                <v-form>
                  <v-text-field
                    name="mobile"
                    label="Mobile No."
                    v-model="profile.mobile"
                    :rules="mobileRules"
                    :counter="11"
                    :error-messages="errors.collect('mobile')"
                    data-vv-name="mobile"
                    required
                    prepend-icon="edit"
                  ></v-text-field>
                </v-form>
                <span
                  >বিঃ দ্রঃ — আপনার আবেদন নিশ্চিত করতে প্রয়োজনীয় ফি জমা দিয়ে এই
                  ফর্মটি অবশ্যই আপনার কলেজের সংশ্লিষ্ট বিভাগে জমা দিতে হবে,
                  অন্যথায় আপনার প্রবেশপত্র প্রদান করা হবে না এবং আপনি পরীক্ষায়
                  অংশগ্রহণ করতে পারবেন না।</span
                >
              </v-card-text>
            </v-card>

            <!-- selected credit show -->

            <v-chip
              class="ma-2"
              color="green"
              text-color="white"
              v-if="getStudentFormFillUpSubject.result_type == 1"
            >
              Selected Credit
              <v-avatar
                right
                class="green darken-4"
                style="margin-left: 8px; margin-right: -12px"
              >
                {{ select_total_credit }}
              </v-avatar>
            </v-chip>

            <slot
              v-if="
                select_total_credit <
                  getStudentFormFillUpSubject.required_credit &&
                getStudentFormFillUpSubject.result_type == 1
              "
            >
              <div class="justify-center layout px-0 mt-2">
                <v-alert :value="true" type="warning">
                  At least
                  {{ getStudentFormFillUpSubject.required_credit }} credits must
                  be selected
                </v-alert>
              </div>
            </slot>
            <slot
              v-if="
                select_total_credit >=
                  getStudentFormFillUpSubject.required_credit ||
                getStudentFormFillUpSubject.result_type == 2
              "
            >
              <div class="justify-center layout px-0 mt-2">
                <v-btn
                  color="success"
                  @click="save"
                  v-if="
                    getStudentFormFillUpFees.total > 0 &&
                    selected.compulsory_subject.length > 0 ||
                    selected.elective_subject.length > 0 ||
                    selected.improvement_subject.length > 0 ||
                    selected.optional_subject.length > 0
                  "
                  >Submit</v-btn
                >
              </div>
            </slot>
            <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      style="margin-top: 50px"
      color="red darken-3"
      :bottom="y === 'bottom'"
      :left="x === 'left'"
      :multi-line="mode === 'multi-line'"
      :right="x === 'right'"
      :top="y === 'top'"
      :vertical="mode === 'vertical'"
    >
      {{ snackbarMessage }}
      <v-btn color="light" flat @click="snackbar = false">
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
            <!-- close selected credit show -->
          </v-container>
          <v-container v-else-if="!getLoading">
            <v-layout row wrap class="mt-auto">
              <span>No data found.</span>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
    <!-- <pre>ffEligibleStatusCheck{{ ffEligibleStatusCheck }}</pre> -->
    <!-- <pre>{{ getFillUpOptions }}</pre> -->
    <!-- <pre>{{ getStudentFormFillUpSubject }}</pre> -->
    <!-- <pre>{{selected}}</pre> -->
    <!-- <pre>{{confirm_by_student}}</pre> -->
  </v-container>
</template>

<script>
// @ts-nocheck
import formNotification from "@/components/utils/formNotification";
import commonFun from "@/mixin/student_common_finction";
import compulsorySubject from "@/components/probable/student_form_fill_up_subject/compulsory_subject";
import electiveSubject from "@/components/probable/student_form_fill_up_subject/elective_subject";
import improvementSubject from "@/components/probable/student_form_fill_up_subject/improvement_subject";
import optionalSubject from "@/components/probable/student_form_fill_up_subject/optional_subject";
import StudentInfo from "@/components/probable/student_form_fill_up_subject/student_info";
import FormFillUpFee from "@/components/probable/student_form_fill_up_subject/FormFillUpFee";
import * as moment from "moment";

export default {
  ff_eligible_status: false,

  name: "FormFillUpSubject",
  mixins: [commonFun],
  components: {
    compulsorySubject,
    electiveSubject,
    improvementSubject,
    optionalSubject,
    StudentInfo,
    FormFillUpFee,
    formNotification,
  },
  computed: {
    getStudentFormFillUpFees() {
      return this.$store.getters.getStudentFormFillUpFees;
    },
    ffEligibleStatusCheck() {
      this.ff_eligible_status = false;
      this.getFillUpOptions.forEach((option) => {
        if (
          option.id == this.getStudentFormFillUpSubject.form_fill_up.id &&
          option.exam_master.activity_setup[0].start_date <= this.today_date &&
          option.exam_master.activity_setup[0].end_date >= this.today_date
        ) {
          this.ff_eligible_status = true;
        }
      });
      return this.ff_eligible_status;
    },
    authStudent() {
      return this.$store.getters.getStudentProfile;
    },
    getLoading() {
      return this.$store.getters.getLoading;
    },
    // getData() {
    //   return this.$store.getters.getStudentFormFillUpSubject;
    // },
    getFillUpOptions() {
      return this.$store.getters.getStudentFormFillUpOptions;
    },
    getStudentFormFillUpSubject() {
      return this.$store.getters.getStudentFormFillUpSubject;
    },
    getSchema() {
      return this.$store.getters.formFillUpConfirmByStudent;
    },
    profile() {
      return this.$store.getters.getStudentProfile;
    },
  },
  data: () => ({
    form_fill_up_id: null,
    today_date: "",
    selected: {
      compulsory_subject: [],
      elective_subject: [],
      improvement_subject: [],
      optional_subject: [],
    },
    select_total_credit: 0,
    confirm_by_student: [],
    mobileRules: [
      (v) => !!v || "মোবাইল নম্বর বাধ্যতামূলক",
      (v) =>
        v.length >= 11 || "মোবাইল নম্বর ১২ অক্ষরের এর কম এবং ১১ অক্ষরের সমান হতে হবে",
        (v) =>
        v.length <= 11 || "মোবাইল নম্বর ১২ অক্ষরের এর কম হতে হবে",
    ],
    snackbar: false,
      timeout: 5000,
      y: "top",
      x: "right",
      mode: "",
      snackbarMessage: "মোবাইল নম্বর ১২ অক্ষরের এর কম এবং ১১ অক্ষরের সমান হতে হবে",
  }),
  watch: {
    "selected.compulsory_subject": function (val, oldval) {
      this.confirmSubject(this.selected);
    },
    "selected.elective_subject": function (val, oldval) {
      this.confirmSubject(this.selected);
    },
    "selected.improvement_subject": function (val, oldval) {
      this.confirmSubject(this.selected);
    },
    "selected.optional_subject": function (val, oldval) {
      this.confirmSubject(this.selected);
    },
    confirm_by_student: function (val, oldval) {},
  },
  created() {
    // this.todayDate();
    this.$store.commit("UNSET_STUDENT_FORM_FILL_UP_OPTIONS");
    this.$store.dispatch("getStudentProfile").then((res) => {
      this.$store.dispatch("fetchStudentFormFillUpOptions", this.authStudent);
    });
    this.form_fill_up_id = this.$route.params.form_fill_up_id;
    this.$store.dispatch("getStudentFormFillUpSubject", this.form_fill_up_id);
    // this.$store.dispatch("setAcademicInfo");
    // this.$store.dispatch("getStudentProfile");
  },
  methods: {
    calculateFeePerStudent(options) {
      this.$store.dispatch("calculateFeePerStudent", options);
      // console.log(options);
    },
    confirmSubject: function (selected) {
      this.select_total_credit = 0;
      this.confirm_by_student = [];
      let all_subjects = [];
      Object.keys(selected).forEach((element) => {
        let subjects = this.selected[element];
        subjects.forEach((element) => {
          all_subjects.push({
            paper_code: element.paper_code,
            form_fill_up_id: element.form_fill_up_id,
            credit: element.core_paper.credit,
            form_fill_up_details_id: element.id,
          });
          this.select_total_credit += parseFloat(element.core_paper.credit);
          // console.log(element.core_paper.credit);
        });
      });
      this.confirm_by_student = all_subjects;
      this.calculateFeePerStudent({
        form_fill_up_id: this.form_fill_up_id,
        paper_code: all_subjects,
      });
      // console.log('all subjects',all_subjects,'selected_total_credit',this.select_total_credit);
    },
    save() {
      let self = this;
      if (self.profile.mobile && self.profile.mobile.length == "11") {
        self.$root.$confirm("Are You Sure To Submit ?").then((confirm) => {
          if (confirm) {
            let item = self.getSchema;
            let student_info = {
              id: self.profile.id,
              mobile: self.profile.mobile,
              reg_no: self.profile.reg_no,
              exam_code:
                self.getStudentFormFillUpSubject.form_fill_up.exam_code,
              degree_code: self.profile.degree_code,
              degree_group_code: self.profile.degree_group_code,
              subject_code: self.profile.subject_code,
              student_type: self.profile.student_type,
              ac_session: self.profile.ac_session,
            };

            const formData = Object.assign(self.getSchema, {
              confirm_subjects: self.confirm_by_student,
              form_fill_up_id: self.form_fill_up_id,
              student_info: student_info,
            });
            const isEmpty = !Object.values(item).some(
              (x) => x !== null && x !== ""
            );
            if (!isEmpty) {
              console.log("formFillUpConfirmByStudent", formData);
              if (self.profile.mobile) {
                self.$store
                  .dispatch("formFillUpConfirmByStudent", formData)
                  .then(function (data) {
                    self.$router.push(
                      `/student/ff/form_fill_up_details/${self.form_fill_up_id}`
                    );
                  })
                  .catch(function (error) {
                    console.log(error);
                    // self.$store.dispatch("fetchAllExamsnData");
                  });
              } else {
                return self.mobileRules;
              }
            } else {
              console.log("empty");
            }
          }
        });
      } else {
        this.snackbar = true;
      }
    },
    todayDate() {
      // var currentDateWithFormat = new Date()
      //   .toJSON()
      //   .slice(0, 10)
      //   .replace(/-/g, "-");
      this.today_date = moment().format("YYYY-MM-DD");
    },
  },
  mounted() {
    this.todayDate();
  },
};
</script>
