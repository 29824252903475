var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[(
      _vm.getStudentFormFillUpSubject.hasOwnProperty('form_fill_up') &&
      _vm.ffEligibleStatusCheck
    )?_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',[_c('v-container',{attrs:{"fluid":"","grid-list-md":""}},[_c('v-toolbar',{attrs:{"flat":"","dark":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(`Form Fill Up for ${_vm.getStudentFormFillUpSubject.form_fill_up.exam_master.exam_name} (${_vm.getStudentFormFillUpSubject.form_fill_up.exam_master.exam_year})`)+" ")])],1)],1)],1)],1),_c('v-flex',{staticClass:"pr-1",attrs:{"xs4":""}},[_c('v-card',[_c('v-container',{attrs:{"fluid":"","grid-list-md":""}},[_c('StudentInfo'),_c('FormFillUpFee'),_c('formNotification')],1)],1)],1),_c('v-flex',{attrs:{"xs8":""}},[_c('v-card',[(_vm.getStudentFormFillUpSubject.total_subject)?_c('v-container',{attrs:{"fluid":"","grid-list-md":""}},[(_vm.getStudentFormFillUpSubject.compulsory_subject.length > 0)?_c('compulsorySubject'):_vm._e(),(
              Object.keys(_vm.getStudentFormFillUpSubject.elective_subject).length
            )?_c('electiveSubject'):_vm._e(),(_vm.getStudentFormFillUpSubject.optional_subject.length > 0)?_c('optionalSubject'):_vm._e(),(_vm.getStudentFormFillUpSubject.improvement_subject.length)?_c('improvementSubject'):_vm._e(),_c('v-toolbar',{attrs:{"flat":"","dark":""}},[_c('v-toolbar-title',[_vm._v("Update Mobile No.")])],1),_c('v-card',[_c('v-card-text',[_c('v-form',[_c('v-text-field',{attrs:{"name":"mobile","label":"Mobile No.","rules":_vm.mobileRules,"counter":11,"error-messages":_vm.errors.collect('mobile'),"data-vv-name":"mobile","required":"","prepend-icon":"edit"},model:{value:(_vm.profile.mobile),callback:function ($$v) {_vm.$set(_vm.profile, "mobile", $$v)},expression:"profile.mobile"}})],1),_c('span',[_vm._v("বিঃ দ্রঃ — আপনার আবেদন নিশ্চিত করতে প্রয়োজনীয় ফি জমা দিয়ে এই ফর্মটি অবশ্যই আপনার কলেজের সংশ্লিষ্ট বিভাগে জমা দিতে হবে, অন্যথায় আপনার প্রবেশপত্র প্রদান করা হবে না এবং আপনি পরীক্ষায় অংশগ্রহণ করতে পারবেন না।")])],1)],1),(_vm.getStudentFormFillUpSubject.result_type == 1)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"green","text-color":"white"}},[_vm._v(" Selected Credit "),_c('v-avatar',{staticClass:"green darken-4",staticStyle:{"margin-left":"8px","margin-right":"-12px"},attrs:{"right":""}},[_vm._v(" "+_vm._s(_vm.select_total_credit)+" ")])],1):_vm._e(),(
              _vm.select_total_credit <
                _vm.getStudentFormFillUpSubject.required_credit &&
              _vm.getStudentFormFillUpSubject.result_type == 1
            )?_vm._t("default",function(){return [_c('div',{staticClass:"justify-center layout px-0 mt-2"},[_c('v-alert',{attrs:{"value":true,"type":"warning"}},[_vm._v(" At least "+_vm._s(_vm.getStudentFormFillUpSubject.required_credit)+" credits must be selected ")])],1)]}):_vm._e(),(
              _vm.select_total_credit >=
                _vm.getStudentFormFillUpSubject.required_credit ||
              _vm.getStudentFormFillUpSubject.result_type == 2
            )?_vm._t("default",function(){return [_c('div',{staticClass:"justify-center layout px-0 mt-2"},[(
                  _vm.getStudentFormFillUpFees.total > 0 &&
                  _vm.selected.compulsory_subject.length > 0 ||
                  _vm.selected.elective_subject.length > 0 ||
                  _vm.selected.improvement_subject.length > 0 ||
                  _vm.selected.optional_subject.length > 0
                )?_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.save}},[_vm._v("Submit")]):_vm._e()],1)]}):_vm._e(),_c('v-snackbar',{staticStyle:{"margin-top":"50px"},attrs:{"timeout":_vm.timeout,"color":"red darken-3","bottom":_vm.y === 'bottom',"left":_vm.x === 'left',"multi-line":_vm.mode === 'multi-line',"right":_vm.x === 'right',"top":_vm.y === 'top',"vertical":_vm.mode === 'vertical'},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarMessage)+" "),_c('v-btn',{attrs:{"color":"light","flat":""},on:{"click":function($event){_vm.snackbar = false}}},[_c('v-icon',[_vm._v("close")])],1)],1)],2):(!_vm.getLoading)?_c('v-container',[_c('v-layout',{staticClass:"mt-auto",attrs:{"row":"","wrap":""}},[_c('span',[_vm._v("No data found.")])])],1):_vm._e()],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }