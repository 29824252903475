var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-toolbar',{attrs:{"flat":"","dark":""}},[_c('v-toolbar-title',[_vm._v("Optional Subject")]),_c('v-spacer')],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.getStudentFormFillUpSubject.optional_subject,"select-all":"","item-key":"paper_code","hide-actions":""},scopedSlots:_vm._u([{key:"headers",fn:function(props){return [_c('tr',[_c('th'),_vm._l((props.headers),function(header){return _c('th',{key:header.text,class:[
            'column sortable',
            _vm.pagination.descending ? 'desc' : 'asc',
            header.value === _vm.pagination.sortBy ? 'active' : ''
          ],on:{"click":function($event){return _vm.changeSort(header.value)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("arrow_upward")]),_vm._v(" "+_vm._s(header.text)+" ")],1)})],2)]}},{key:"items",fn:function(props){return [(
          !props.item.prev_grade &&
            props.item.core_paper.paper_type_info.name == 'optional'
        )?_c('tr',{attrs:{"active":props.selected},on:{"click":function($event){props.selected = !props.selected}}},[_c('td',[_c('v-checkbox',{attrs:{"input-value":props.selected,"primary":"","hide-details":""}})],1),_c('td',[_vm._v(_vm._s(props.item.core_paper.paper_name))]),_c('td',{staticClass:"text-xs-center"},[_vm._v(_vm._s(props.item.core_paper.paper_code))]),_c('td',{staticClass:"text-xs-center"},[_vm._v(" "+_vm._s(props.item.core_paper.paper_types.paper_type)+" ")])]):_vm._e()]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }