var render = function render(){var _vm=this,_c=_vm._self._c;return (
    Object.keys(_vm.getStudentFormFillUpSubject.compulsory_subject).length > 0
  )?_c('div',[_c('v-toolbar',{attrs:{"flat":"","dark":""}},[_c('v-toolbar-title',[_vm._v("Compulsory Subject")]),_c('v-spacer'),_c('v-toolbar-items',{staticClass:"hidden-sm-and-down"},[_c('v-btn',{attrs:{"flat":""}},[(_vm.$parent.$parent.confirm_by_student.length)?_c('span',[_vm._v("Selected Paper Code -[")]):_vm._e(),_vm._l((_vm.$parent.$parent.confirm_by_student),function(selected,index){return _vm._t("default",function(){return [(index)?_c('span',{key:index},[_vm._v(","+_vm._s(selected.paper_code))]):_c('span',{key:index},[_vm._v(_vm._s(selected.paper_code))])]})}),(_vm.$parent.$parent.confirm_by_student.length)?_c('span',[_vm._v("]")]):_vm._e()],2)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.getStudentFormFillUpSubject.compulsory_subject,"select-all":"","item-key":"paper_code","hide-actions":""},scopedSlots:_vm._u([{key:"headers",fn:function(props){return [_c('tr',_vm._l((props.headers),function(header){return _c('th',{key:header.text,class:[
            'column sortable',
            _vm.pagination.descending ? 'desc' : 'asc',
            header.value === _vm.pagination.sortBy ? 'active' : ''
          ],on:{"click":function($event){return _vm.changeSort(header.value)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("arrow_upward")]),_vm._v(" "+_vm._s(header.text)+" ")],1)}),0)]}},{key:"items",fn:function(props){return [(
          (!props.item.prev_grade &&
            props.item.core_paper.paper_type_info.name == 'compulsory') ||
            //props.item.ff_type == 'F'
            props.item.ff_type == 1
        )?_c('tr',{attrs:{"active":props.selected},on:{"click":function($event){props.selected = props.selected}}},[_c('td',{staticClass:"my-0 py-0"},[_vm._v(_vm._s(props.item.core_paper.paper_name))]),_c('td',{staticClass:"text-xs-center my-0 py-0"},[_vm._v(" "+_vm._s(props.item.core_paper.paper_code)+" ")]),_c('td',{staticClass:"text-xs-center my-0 py-0"},[_vm._v(" "+_vm._s(props.item.core_paper.paper_types.paper_type)+" ")]),_c('td',{staticClass:"text-xs-center my-0 py-0"},[_vm._v(_vm._s(props.item.prev_grade))])]):_vm._e()]}}],null,false,454908858),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }